

input,select{
    padding: 14px 6px !important;
    font-family: Inter, sans-serif !important;
    font-weight: 400;
    line-height: 1.5;
    color:#474946;
    background: white;

}

.valorelement{
    padding: 5px !important;
    background-color: #ebebd3 !important;
}

.datatime{
    padding: 10px !important;
    background: white;
}