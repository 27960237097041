body {
  background-color:#ebebd3 !important;
  height: 1000%; 
  width: 100%;  
  margin: 0px !important;
}


body {
  background-image: url('Assets/Imagenes/Home/fondo - copia.png'); /* Ajusta la ruta según la ubicación de tu imagen */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  height: 1000%; /* Opcional: establece la altura en 100% del viewport */
  width: 100%;  /* Opcional: establece el ancho en 100% del viewport */
  margin: 0px;
}


.Tipografia_base{
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color:#474946;
}



.LH_05{
  line-height: .5 !important;
}
.LH_1{
  line-height: 1 !important;
}
.LH_105{
  line-height: 1.5 !important;
}
.LH_2{
  line-height: 2 !important;
}



.FW_1{
  font-weight: 100 !important;
}
.FW_2{
  font-weight: 200 !important;
}
.FW_3{
  font-weight: 300 !important;
}
.FW_4{
  font-weight: 400 !important;
}
.FW_5{
  font-weight: 500 !important;
}
.FW_6{
  font-weight: 600 !important;
}
.FW_7{
  font-weight: 700 !important;
}
.FW_8{
  font-weight: 800 !important;
}
.FW_9{
  font-weight: 900 !important;
}



.FZ_8{
  font-size: 8px !important;
}
.FZ_9{
  font-size: 9px !important;
}
.FZ_10{
  font-size: 10px !important;
}
.FZ_11{
  font-size: 11px !important;
}
.FZ_12{
  font-size: 12px !important;
}
.FZ_13{
  font-size: 13px !important;
}
.FZ_14{
  font-size: 14px !important;
}
.FZ_15{
  font-size: 15px !important;
}
.FZ_16{
  font-size: 16px !important;
}
.FZ_17{
  font-size: 17px !important;
}
.FZ_18{
  font-size: 18px !important;
}
.FZ_19{
  font-size: 19px !important;
}
.FZ_20{
  font-size: 20px !important;
}
.FZ_21{
  font-size: 21px !important;
}
.FZ_22{
  font-size: 22px !important;
}
.FZ_23{
  font-size: 23px !important;
}
.FZ_24{
  font-size: 24px !important;
}
.FZ_28{
  font-size: 28px !important;
}
.FZ_32{
  font-size: 32px !important;
}
.FZ_36{
  font-size: 36px !important;
}